import * as React from 'react'
import Popup from 'reactjs-popup'
import MetaMaskPopup from '../Popups/MetaMask'
import {AnyObject} from "../../shared/types";
import {consumer as Consumer} from "../Provider";
import PromiEvent from "web3/promiEvent";
import {TransactionReceipt} from 'web3-core';
import {WithTranslation, withTranslation} from "react-i18next";
import Utils from '../../utils';
import eth from '../../assets/img/eth.png';
import StatsRows from "../Main/partials/StatsRows";


class Cases extends React.PureComponent<T> {
    private readonly onResize = (() => this.setState({windowWidth: window.innerWidth}));
    state: ICasesState = {
        price: this.props.price,
        prize: undefined,
        popup: false,
        windowWidth: window.innerWidth,
        transactionHash: ''
    };
    componentWillUnmount(): void {
        window.removeEventListener('case-opened', this.caseOpened);
    }

    private openCase() {
        this.setState({popup: true});
        if (!window.ethereum) return;

        this.props.openCase((this.props.price * 10 ** 18).toString(), (_this: PromiEvent<TransactionReceipt>) =>
            _this.on("transactionHash", () => {
                this.setState({price: undefined, prize: undefined, popup: false});
                (document.getElementsByClassName('loading-content')[0] as HTMLAnchorElement).style.display = "block";
            }).then(receipt => {
            }).catch(e => {
                (document.getElementsByClassName('loading-content')[0] as HTMLAnchorElement).style.display = "none";
                this.setState({popup: false});
            }))
    }

    private caseOpened = (caseOpened: any): void => {
        if (this.props.address.toUpperCase() === caseOpened.data.user.toUpperCase()) {
            const prize =  caseOpened.data.prize.toString() / 10 ** 18;
            this.setState({prize});
        }
    }
    private closePopup() {
        this.setState({popup: false});
    }

    render() {
        window.addEventListener("resize", this.onResize);
        window.addEventListener('case-opened', this.caseOpened);
        let prizes: number[];
        return <React.Fragment>
            <section className="case-rookie" style={{display: "none"}}>
                <Consumer>{context =>
                    (prizes = (context.prizes[this.props.price] || [])).length ? prizes[0] : "—"
                }</Consumer>
            </section>
            <section className="container">
                <div className="info_for_case">
                    <div className="col">
                        <h2>
                            <span className="loading-content">{this.state.prize
                                ? this.props.t('win-text') + this.state.prize + ' ETH' : this.props.t('transaction-created')}<br/><br/></span>
                            {this.props.t('case')} <strong>100%</strong> {this.props.t('contains-small')}:
                        </h2>
                        <div className="presents">
                            <Consumer>{() => prizes.map((prize, i) =>
                                <div key={i} className="item">
                                    <img src={eth} alt=""/>
                                    {Utils.formatNumber(prize)} ETH
                                </div>
                            )}</Consumer>
                        </div>
                    </div>
                    <div className="col case_back">
                        <a onClick={this.openCase.bind(this)} href="#" className="btn_default open-case-button">{this.props.t('open-for')}&nbsp;
                            {this.props.price} ETH</a>

                    </div>
                    <div className="col ltr">
                        <h2>
                            {this.props.t('already-got')}:
                        </h2>
                        <h2>
                            <Consumer>
                                {context => context.casesWins[this.props.price]}
                            </Consumer>
                            <span>>&nbsp;ETH</span>
                        </h2>
                    </div>
                </div>
                <div className="status_bar">
                    <div className="col">
                        <h2>
                            {this.props.t('users')}
                        </h2>
                        <span className="count green">
                            <Consumer>
                                {context => context.usersCount === undefined ? '—' : context.usersCount}
                            </Consumer>
                        </span>
                    </div>
                    <div className="col">
                        <h2>
                            {this.props.t('open-cases')}
                        </h2>
                        <span className="count">
                            <Consumer>
                                {context => context.usersCount === undefined ? '—' : context.openedCasesCount}
                            </Consumer>
                        </span>
                    </div>
                    <div className="col grow-1">
                        <h2>
                            {this.props.t('winners-24')}
                        </h2>
                        <Consumer>{context =>
                            <StatsRows windowWidth={this.state.windowWidth} obj={context.usersStats} prop="wins"/>}
                        </Consumer>
                    </div>
                </div>
            </section>
            <Popup open={this.state.popup} onClose={() => this.setState({popup: false})}>
                <MetaMaskPopup
                    closePopup={this.closePopup.bind(this)}
                    title={window.ethereum ? this.props.t('confirmation') : this.props.t('no-metamask')}
                    text={window.ethereum ? this.props.t('confirm-metamask') : this.props.t('install-metamask')}
                />
            </Popup>
        </React.Fragment>
    }
}

export default withTranslation()(Cases)

interface ICasesState {
    price: number | undefined
    prize: number | undefined
    popup: boolean
    windowWidth: number
    transactionHash: string
}

interface ICases {
    openCase: ((value: string, callback: (_this: PromiEvent<TransactionReceipt>) => Promise<void>) => PromiEvent<TransactionReceipt>)
    decodeLog: (receipt: AnyObject) => AnyObject
    price: number
    address: string
}

interface T extends ICases, WithTranslation {
}
