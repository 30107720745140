import * as React from 'react';
import {AnyObject} from "../../../shared/types";
import Utils from "../../../utils";
import {WithTranslation, withTranslation} from "react-i18next";


class StatsRows extends React.PureComponent<T> {
    render() {
        const {obj, prop} = this.props;
        return <React.Fragment>
            {Object.keys(obj)
                .sort((a, b) => obj[a][prop] < obj[b][prop] ? 1 : (obj[a][prop] > obj[b][prop] ? -1 : 0))
                .slice(0, 3).map((key, i) =>
                <section className="best_player" key={i}>
                            <span className="orange">
                                {i + 1}
                            </span>
                  <a href="#" className="hesh_nums">
                      {this.props.windowWidth > 500 ? key : `${key.slice(0, 3)}...${key.slice(key.length - 4)}`}
                  </a>

                  <div className="top_bet yellow">
                    <span className="title">{this.props.t('winning-amount')}</span>
                    <span className="eth_values">
                                    {prop === 'wins'
                                        ? Utils.formatNumber(obj[key][prop])
                                        : obj[key][prop]
                                    }
                                </span>
                  </div>
                </section>
                )}
        </React.Fragment>
    }
}

export default withTranslation()(StatsRows);

interface IStatsRows {
    obj: AnyObject
    prop: string
    windowWidth: number
}

interface T extends IStatsRows, WithTranslation {
}
