import * as React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';

class Guarantees extends React.PureComponent<WithTranslation> {
    render() {
        return <div className="description_about_us">
            <h2 className="orange">
                {this.props.t('guarantees')}
            </h2>

            <div>
                <p>{this.props.t("why-trust")}</p>
                <ol style={{marginLeft: "15px"}}>
                    <li style={{marginTop: "10px"}}>1. {this.props.t("smart-contract")}</li>
                    <li style={{marginTop: "10px"}}>2. {this.props.t("ethereum")}</li>
                    <li style={{marginTop: "10px"}}>3. {this.props.t("no-influence")}</li>
                    <li style={{marginTop: "10px"}}>4. {this.props.t("no-register")}</li>
                    <li style={{marginTop: "10px"}}>5. {this.props.t("no-private-keys")}</li>
                    <li style={{marginTop: "10px"}}>6. {this.props.t("100-withdrawal")}</li>
                </ol>
                <p>{this.props.t("smart-contract-link")} — <a href="https://etherscan.io/address/0xc118FF4762bD568231678918f6C888DdA7d6446A#code">Etherscan</a></p>
            </div>
        </div>
    }
}

export default withTranslation()(Guarantees);
