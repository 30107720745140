import * as React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';

class About extends React.PureComponent<WithTranslation> {
    render() {
        return <div className="description_about_us">
                <h2 className="orange">
                    {this.props.t('we-bring')}
                </h2>
                <p>
                    {this.props.t('you-dont-need')} <a>MetaMask</a> {this.props.t('wallet')}.
                </p>
                <p>
                    {this.props.t('what-is')}
                </p>

                <p className="bigger">
                    {this.props.t('questions')} <a href="mailto:support@GuessGame.pro"> support@guessgame.pro</a>
                </p>
            </div>
    }
}

export default withTranslation()(About);
