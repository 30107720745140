import * as React from 'react';
import config from '../../config';
import Ethereum from "../../ethereum";
import {coinsMap} from "../../shared/types";
import StatsRows from "./partials/StatsRows";
import {consumer as Consumer} from "../Provider";
import Contract from "web3/eth/contract";
import {WithTranslation, withTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

class Main extends React.PureComponent<T> {
    private readonly onResize = (() => this.setState({windowWidth: window.innerWidth}));
    state: IMainState = {
        totalWins: undefined,
        caseOpenings: [],
        windowWidth: window.innerWidth
    };

    componentDidMount(): void {
        window.addEventListener("resize", this.onResize);
        if (!this.props.contract) return;
        Promise.all([
            Ethereum.callContract(this.props.contract, 'totalWins'),
            ...config.coins.slice(0,1).map(c => Ethereum.callContract(this.props.contract, 'caseOpenings', [(c.price * 10 ** 18).toString()])
                .then(openings => ({price: c.price, openings: parseInt(openings.toString())})))
        ]).then(data => {
            const [totalWins, ...caseOpenings] = data;
            this.setState({
                totalWins: parseInt(totalWins.toString()) / 10 ** 18,
                caseOpenings: [...this.state.caseOpenings, ...caseOpenings]
                    .sort((a, b) => a.openings < b.openings ? 1 : (a.openings > b.openings ? -1 : 0))
            });
        })
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.onResize)
    }

    render() {
        const caseOpenings = this.state.caseOpenings;
        const popular = caseOpenings.length && caseOpenings.filter(c => c.openings > 0) ? caseOpenings[0].price : "";
        let profit: number;
        let prizes: number[];
        let tmp: number[] | undefined;

        return <React.Fragment>
            <section className="container">
                <div className="status_bar">
                    <div className="col">
                        <h2>
                            {this.props.t('users')}
                        </h2>
                        <span className="count green">
                            <Consumer>
                                {context => context.usersCount === undefined ? '—' : context.usersCount}
                            </Consumer>
                        </span>
                    </div>
                    <div className="col">
                        <h2>
                            {this.props.t('open-cases')}
                        </h2>
                        <span className="count">
                            <Consumer>
                                {context => context.usersCount === undefined ? '—' : context.openedCasesCount}
                            </Consumer>
                        </span>
                    </div>
                    <div className="col grow-1">
                        <h2>
                            {this.props.t('winners-24')}
                        </h2>
                        <Consumer>{context =>
                            <StatsRows windowWidth={this.state.windowWidth} obj={context.usersStats} prop="wins"/>}
                        </Consumer>
                    </div>
                </div>
                <div className="cases">
                    {config.coins.map((coin, i) =>
                      <div className="item" key={i}>
                        <div className="header">
                          <div className="status_prices">
                            <span className="title">
                                {this.props.t('winning-amount')}:
                            </span>
                            <span className="price">

                                <Consumer>
                                    {context => context.casesWins[coin.price]}
                                </Consumer>
                                <span>>&nbsp;ETH</span>
                            </span>
                          </div>
                          <div className="status_prices">
                            <span className="title">
                                {this.props.t('price')}:
                            </span>
                            <span className="price">
                                {coin.price} eth
                            </span>
                          </div>
                        </div>
                        <figure>
                          <img src={coinsMap[coin.price]} alt=""/>
                        </figure>
                        <span className="case">
                            {this.props.t('case')}
                        </span>
                        <span className="name_case">
                            {this.props.t(coin.name)}
                        </span>
                        <p className="description">
                          <strong>100% win </strong>
                            <Consumer>
                                {context => <span>
                                    {this.props.t('from')} {(prizes = (context.prizes[coin.price] || [])).length
                                    ? prizes[0] : "—"} {this.props.t('to')}&nbsp;{prizes.length ? prizes[prizes.length - 1] : "—"}&nbsp;ETH
                                </span>}
                            </Consumer>
                        </p>

                        <NavLink to={"/cases/" + coin.price} className="button btn_default">{this.props.t('open')}</NavLink>
                      </div>
                    )}
                </div>
            </section>
        </React.Fragment>;
    }
}

export default withTranslation()(Main)

interface IMain {
    contract: Contract
}

interface IMainState {
    totalWins: undefined | number
    caseOpenings: { price: number, openings: number }[]
    windowWidth: number
}

interface T extends IMain, WithTranslation {
}
